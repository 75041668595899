<script>
// 本地
// const httpUrl="http://www.logic.com/"
// server
 const httpUrl="https://logic.morningstarsystem.tech/"

// const httpUrl="http://42.192.179.198/"

export default{

    httpUrl
}
</script>
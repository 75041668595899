<template>
  <div id="content">
    <el-container>
      <el-aside width="110px" style="overflow: hidden!important;;">
      <div id="logo">
        Logics
      </div>
        <div id="nav">
          <ul>
            <li><router-link to="/excel" active-class="router-link-active"><i class="el-icon-document"></i> 项目管理</router-link></li>
            <li><router-link to="/chi" active-class="router-link-active"><i class="el-icon-attract"></i> 同题互斥</router-link></li>
            <li><router-link to="/yin" active-class="router-link-active"><i class="el-icon-sort"></i> 前因选择</router-link></li>
            <li><router-link to="/hong" active-class="router-link-active"><i class="el-icon-s-operation"></i> 宏</router-link></li>
            <li v-if="level"><router-link to="/set" active-class="router-link-active"><i class="el-icon-setting"></i> 系统设置</router-link></li>
          </ul>
        </div>

      </el-aside>
      <el-container>
         <el-header>
           <p class="login" v-if="uname">欢迎您, {{uname}}  <el-button size="mini" round style="margin:0 10px" @click="exit">注销</el-button></p>
          <p>当前位置>{{weizhi}}</p>
         
          
          </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      </el-container>
      
    </el-container>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Head from './components/PageHead'

export default {
  data(){
    return{
      weizhi:'首页',
      uname:'12',
      level:'',
    }
  },
  name: 'PageIndex',
  components: {
    // Head
  },
   methods: {
    exit(){
        localStorage.removeItem('token')
       this.$router.push("/login");
    }
   },
  created(){
     const uname = localStorage.getItem('uname')
     const level = localStorage.getItem('level')
       if (uname) {
            this.uname=uname
            if(level==1){
                this.level=1
            }
            
       }
  },
  watch:{
    $route(newValue){
      //console.log(newValue,oldValue)      
      switch(newValue.name){
        case "excel":
          this.weizhi="项目管理"
          break
          case "chi":
            this.weizhi="同题互斥"
            break
            case "yin":
              this.weizhi="前因选择"
              break
             case "hong":
              this.weizhi="宏"
              break
              case "set":
              this.weizhi="系统设置"
              break
              default:
                 this.weizhi="首页"
             
      }
    }
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}
.login{
  float:right;
}
#content {
  height: 100%;
  background-color: #E9EEF3;
}
 

.el-container {
  height: 100%;
 
}

.el-aside {
  height: 100%;
  background-color: #1b315e;
  color: #fff;
  text-align: center;
  
}
#logo{
  height: 100px;
  line-height: 100px;
  font-weight: 800;
  text-align: center;
  color:#409eff;
  border-bottom: 0px solid white;
}
#nav {
  margin-top: 150px;
  height: 200px;
  border:0px solid red;

}

#nav ul {
  list-style: none;
}

#nav li {
  padding:0;
  margin:0;
  text-align: left;
  height: 60px;
  line-height: 60px;
  width: 100%;

  border-bottom: 1px solid #0e1f3f;
  /* background-color: #233a68; */
}
#nav li:hover{
  background-color: #0e2046;
}
#nav li a{
  padding-left: 10px;
  display: block;
  width:100%;
  height:100%;
color:#fff;
text-decoration: none;
}
.router-link-active{
   background-color: #0e2046;

}
.el-header {
  font-size:13px;
  color:#409eff;
  margin-top:5px;
  margin-left:10px;
  height: 50px!important;
  line-height: 50px;
background-color:white;
  padding-left:50px!important;
}
.el-main {
    margin-top:5px;
  margin-left:10px;
  /* height: 100%; */
  background-color: white!important;
  color: #333;
  padding-left:50px!important;
  padding-top:50px!important;

}
</style>

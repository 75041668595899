<template>
    <div>
     
           <el-tabs v-model="activeName" type="card" @tab-click="handleClick" lazy="true">
                <el-tab-pane label="创建项目" name="first">
                    <PageExcelUp></PageExcelUp>                
                </el-tab-pane>
                <el-tab-pane label="项目列表" name="second">
                     <PageExcelList v-bind:biao="biao"></PageExcelList>    
                </el-tab-pane>
            </el-tabs>
    </div>
</template>
<script>
import PageExcelUp from './PageExcelUp'
import PageExcelList from './PageExcelList'
export default {
    name: 'PageExcel',
    props: {
        type: String
    },
    created() {

         if (localStorage.getItem("excelactive")) {
            this.activeName = localStorage.getItem("excelactive")
        }

     
    },
    data() {
        return {
            activeName: 'first',
            biao:{}
        };
    },
    
    components:{
        PageExcelUp,PageExcelList
    },
    methods: {
         
        handleClick() {
             //为了切换后,list重新请求数据,监听对象变化
           localStorage.setItem("excelactive", this.activeName)
           console.log(this.activeName)
           this.biao={current:this.activeName}
        }
    }

}
</script>
<style>

</style>
<template>
    <div>
     
           <el-tabs v-model="activeName" type="card" @tab-click="handleClick" lazy="true">
                <el-tab-pane label="用户管理" name="first">
                    <PageSetAdmin></PageSetAdmin>                
                </el-tab-pane>
            </el-tabs>
    </div>
</template>
<script>
import PageSetAdmin from './PageSetAdmin'

export default {
    name: 'PageSet',
    props: {
        type: String
    },
    created() {

        //  if (localStorage.getItem("excelactive")) {
        //     this.activeName = localStorage.getItem("excelactive")
        // }

     
    },
    data() {
        return {
            activeName: 'first',
            biao:{}
        };
    },
    
    components:{
       PageSetAdmin
    },
    methods: {
         
        handleClick() {
        //      //为了切换后,list重新请求数据,监听对象变化
        //    localStorage.setItem("excelactive", this.activeName)
        //    console.log(this.activeName)
        //    this.biao={current:this.activeName}
        }
    }

}
</script>
<style>

</style>
<template>
  <div>
    <div id="content">

      <el-row :gutter="70">
        <el-col :span="14">

          <div>
            <el-select v-model="pid" placeholder="请选择项目" class="inp" @change="changexm">
              <el-option v-for="item in optionp" :key="item.id" :label="item.pname" :value="item.id"></el-option>
            </el-select>

            <el-button type="primary" style="float:right;" @click="onSubmit" :loading="btload">添加</el-button>

          </div>
          <div class="part0">
            <el-divider content-position="left">KEY</el-divider>
          </div>
          <div class="part1">
            <div style="height: 30px;">
              <p class="tips" v-if="qtype != ''">Question Type:<span>{{ qtype }}</span></p>
            </div>

            <el-select class="inp" v-model="valueq" clearable filterable @change="changeselect(1)"
              placeholder="请输入Question ID/ Variable ID">
              <el-option v-for="item in optionsq" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>


            <el-select class="inp" v-model="valuea" clearable :multiple="multiple" filterable allow-create collapse-tags
              default-first-option placeholder="请选择Answer Label">
              <el-option v-for="item in optiona" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>




          </div>
          <div class="part2">

            <el-radio-group v-model="expression" size="mini">
              <el-radio-button label="1" :disabled="able[1]">单选</el-radio-button>
              <el-radio-button label="2" :disabled="able[2]">任一选中</el-radio-button>
              <el-radio-button label="3" :disabled="able[3]">均未选中</el-radio-button>
              <el-radio-button label="4" :disabled="able[4]">大于</el-radio-button>
              <el-radio-button label="5" :disabled="able[5]">大于等于</el-radio-button>
              <el-radio-button label="6" :disabled="able[6]">小于</el-radio-button>
              <el-radio-button label="7" :disabled="able[7]">小于等于</el-radio-button>
              <el-radio-button label="8">null</el-radio-button>
              <el-radio-button label="9">!null</el-radio-button>
              <el-radio-button label="10">&</el-radio-button>
              <el-radio-button label="11">or</el-radio-button>
              <el-radio-button label="12">like</el-radio-button>
              <el-radio-button label="13">!like</el-radio-button>

            </el-radio-group>


          </div>


          <div class="part3">

            <el-input spellcheck="false" placeholder="内容生成,可编辑" type="textarea" v-model="product" class="product"
              rows="3"></el-input>
          </div>
          <div class="part4">
            <el-button type="info" size="small" @click="resets(1, 1)">重置</el-button>
            <el-button type="warning" size="small" @click="make">解析表达式</el-button>

          </div>

          <!-- ********* value部分 ************* -->

          <div class="part0">
            <el-divider content-position="left">VALUES</el-divider>
          </div>
          <div class="part1">
            <div style="height: 30px;">
              <p class="tips" v-if="qtype2 != ''">Question Type:<span>{{ qtype2 }}</span></p>
            </div>


            <el-select class="inp" v-model="valueq2" clearable filterable @change="changeselect(2)"
              placeholder="请输入Question ID/ Variable ID">
              <el-option v-for="item in optionsq" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-select class="inp" v-model="valuea2" clearable :multiple="multiple" filterable allow-create collapse-tags
              default-first-option placeholder="请选择Answer Label">
              <el-option v-for="item in optiona2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>




          </div>
          <div class="part2">

            <el-radio-group v-model="expression2" size="mini">
              <el-radio-button label="1" :disabled="able2[1]">选中</el-radio-button>
              <el-radio-button label="2" :disabled="able2[2]">未选中</el-radio-button>
              <el-radio-button label="3" :disabled="able2[3]">大于</el-radio-button>
              <el-radio-button label="4" :disabled="able2[4]">大于等于</el-radio-button>
              <el-radio-button label="5" :disabled="able2[5]">小于</el-radio-button>
              <el-radio-button label="6" :disabled="able2[6]">小于等于</el-radio-button>
              <el-radio-button label="7" :disabled="able2[7]">&</el-radio-button>
              <el-radio-button label="8">null</el-radio-button>
              <el-radio-button label="9">!null</el-radio-button>
              <el-radio-button label="10">like</el-radio-button>
              <el-radio-button label="11">!like</el-radio-button>

            </el-radio-group>


          </div>


          <div class="part3">

            <el-input spellcheck="false" placeholder="内容生成,可编辑" type="textarea" v-model="product2" class="product"
              rows="3"></el-input>
          </div>
          <div class="part4">
            <el-button type="info" size="small" @click="resets(2, 1)">重置</el-button>
            <el-button type="warning" size="small" @click="make2">解析表达式</el-button>

          </div>



        </el-col>
        <el-col :span="10">

          <div class="op">
            <el-button type="success" icon="el-icon-circle-plus-outline" @click="savedel(2)">提交保存</el-button>
            <el-button type="danger" icon="el-icon-delete" @click="del">删除</el-button>
          </div>
          <el-divider content-position="left"></el-divider>
          <el-table :data="tableData" ref="multipleTable" style="width:100%">
            <el-table-column type="selection">
            </el-table-column>
            <el-table-column prop="aid" label="Aid" width="80" v-if="false">
            </el-table-column>
            <el-table-column prop="type" label="Type" width="80">
            </el-table-column>
            <el-table-column prop="id" label="Id" width="40">
            </el-table-column>
            <el-table-column sortable prop="key" label="Key">
            </el-table-column>
            <el-table-column prop="values" label="Values">
            </el-table-column>
          </el-table>

        </el-col>
      </el-row>

    </div>
  </div>
</template>
<script>
export default {
  name: 'PageYin',
  props: {
    type: String
  },
  data() {

    return {
      btload: false,
      pid: [], //项目id-- 通用
      optionp: [], //项目选项-- 通用
      tableData: [],
      able: [false, false, false, false, false, false, false, false, false, false],
      expression: '',//表达式
      expres: ["0", "==", "", "!=", ">", ">=", "<", "<=", "=null", "!=null", " and ", " or "],
      qtype: '', //当前answer Type
      multiple: true,
      product: '', //key生成的表达式
      optionsq: [],//question选项-- 通用
      valueq: [],//question 值
      optiona: [], //anser选项
      valuea: [],  //answer值

      // values部分
      able2: [false, false, false, false, false, false, false, false],
      expression2: '',
      expres2: ["0", "=", "!=", ">", ">=", "<", "<=", ",", "=null", "!=null"],
      product2: '',//values
      qtype2: '', //当前answer Type
      valueq2: [],//question 值
      optiona2: [], //anser选项
      valuea2: []  //answer值

    }
  },

  created() {

    var api = this.COMMON.httpUrl + "projectlist"
    var data = {
      pid: this.pid
    }
    this.axios.post(api, data).then((response) => {
      var rs = response.data
      if (rs.code) {
        this.$message.error(rs.message);
      } else {
        this.optionp = rs.message.data
        if (localStorage.getItem("selectPid") > 0) {
          this.pid = parseInt(localStorage.getItem("selectPid"))
          this.show()
        }


      }

    })

  },
  methods: {
    //改变左侧下拉框,更新右侧下拉选项
    changeselect(tp) {

      var vid = this.valueq
      if (tp == 2) {
        vid = this.valueq2
      }
      var api = this.COMMON.httpUrl + "yinoption"
      var data = {
        vid: vid,
        pid: this.pid
      }
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          if (tp == 1) {
            this.optiona = rs.message.data
            this.qtype = rs.message.type
          } else {
            this.optiona2 = rs.message.data
            this.qtype2 = rs.message.type
          }
          this.chable(tp, rs.message.type)

        }

      })

    },
    //更改项目
    changexm(v) {
      var vi = parseInt(v)
      localStorage.setItem("selectPid", vi)
      this.show()
    },
    //初始化加载数据
    show() {

      if (localStorage.getItem("selectPid") > 0) {
        this.pid = parseInt(localStorage.getItem("selectPid"))
      }

      var api = this.COMMON.httpUrl + "yinSelect"
      var data = {
        pid: this.pid,
        tp: "前因选择"
      }
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.optionsq = rs.message.optionsq;
          this.optionp = rs.message.optionp
          this.tableData = rs.message.tabledata
        }

      })
    },
    //改变表达式可选
    chable(tp, t) {
      if (tp == 1) {
        switch (t) {
          case "ranking":
            this.able = [false, false, true, false, false, false, false, false]
            break
          case "numericlist":
            this.able = [false, false, true, false, false, false, false, false]
            break
          case "multi":
            this.able = [false, false, false, false, true, true, true, true]
            break
          default:
            this.able = [false, false, false, false, false, false, false, false]
        }
      } else {
        switch (t) {
          case "ranking":
            this.able2 = [false, false, false, false, false, false, false, false]
            break
          case "numericlist":
            this.able2 = [false, false, false, false, false, false, false, false]
            break
          case "multi":
            this.able2 = [false, false, false, true, true, true, true, false]
            break
          default:
            this.able2 = [false, false, false, false, false, false, false, false]
        }
      }

    },

    //添加暂存
    onSubmit() {
      this.btload = true
      var api = this.COMMON.httpUrl + "yinadd"
      var data = {
        pid: this.pid,
        key: this.product,
        values: this.product2
      }
      this.axios.post(api, data).then((response) => {
        this.btload = false
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);

        } else {
          this.tableData = rs.message
          this.product = ''
          this.product2 = ''

        }

      })

    },

    //重置
    resets(tp, ps) {

      if (tp == 1) {

        this.valueq = ''
        this.valuea = ''
        this.expression = ''
        this.qtype = ''
        if (ps == 1) {
          this.product = ''
        }

        this.able = [false, false, false, false, false, false, false, false]

      } else {

        this.valueq2 = ''
        this.valuea2 = ''
        this.expression2 = ''
        this.qtype2 = ''
        if (ps == 1) {
          this.product2 = ''
        }
        this.able2 = [false, false, false, false, false, false, false, false]

      }


    },
    //key部分表达式解析
    make() {

      //1，判断连接符
      if (this.expression == "11" || this.expression == "10") {
        this.product += this.expres[this.expression]
        this.resets(1)
        return
      }

      // !like 
      if (this.expression == "13") {

        if (this.product.includes("!like")) {
          let arr = this.product.split("!like")
          let as = arr[1]
          let b = JSON.parse(as)
          this.product = b.toString()
        } else {
          let a = this.product
          a = a.replaceAll("，", ",")
          let b = a.split(",")
          let c = JSON.stringify(b)
          this.product = this.valueq + " !like " + c
        }
        return
      }

      // like 
      if (this.expression == "12") {

        if (this.product.includes("like")) {
          let arr = this.product.split("like")
          let as = arr[1]
          let b = JSON.parse(as)
          this.product = b.toString()
        } else {
          let a = this.product
          a = a.replaceAll("，", ",")
          let b = a.split(",")
          let c = JSON.stringify(b)
          this.product = this.valueq + " like " + c
        }
        return
      }

      // null !null
      if (this.expression == "8") {
        this.product += this.valueq + " = null"
        this.resets(1)
        return
      }
      if (this.expression == "9") {
        this.product += this.valueq + " != null"
        this.resets(1)
        return
      }

      //2,判断是不是都选上了
      if (this.valueq == '') {
        this.$message.error("请输入Question ID/ Variable ID");
        return
      }
      //3, multi不需要选answerlabel
      if (this.qtype != "multi" && this.qtype != "ranking" && this.qtype != "numericlist" && this.valuea == '') {
        this.$message.error("请选择Answer Label");
        return
      }
      if (this.expression == '') {
        this.$message.error("请选择表达式");
        return
      }
      //4，判断单多选，为任一选中/均未选中时才能选多项
      if (this.expression != "2" && this.expression != "3" && this.valuea.length > 1) {
        this.$message.error("该条件下Answer Label不能多选，请重新选择");
        return
      }


      //5,根据不同题型，分别处理

      switch (this.qtype) {
        case "single":

          switch (this.expression) {
            case "2":
              //任一选中
              this.product += this.valueq + " in [" + this.valuea + "]"
              break
            case "3":
              //均未选中
              var s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = this.valueq + "!=" + item
                } else {
                  s += " and " + this.valueq + "!=" + item
                }
              }
              this.product += s
              break
            default:
              //其他表达式
              this.product += this.valueq + this.expres[this.expression] + this.valuea
          }

          break
        case "grid":
          switch (this.expression) {
            case "2":
              //任一选中
              this.product += this.valueq + " in [" + this.valuea + "]"
              break
            case "3":
              //均未选中
              s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = this.valueq + "!=" + item
                } else {
                  s += " and " + this.valueq + "!=" + item
                }
              }
              this.product += s
              break
            default:
              //其他表达式
              this.product += this.valueq + this.expres[this.expression] + this.valuea
          }

          break
        case "multi":
          switch (this.expression) {
            case "2":
              //任一选中

              s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = item + "==1"
                } else {
                  s += " or " + item + "==1"
                }
              }
              this.product += s

              break
            case "3":
              //均未选中
              
              s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = item + "==0"
                } else {
                  s += " and " + item + "==0"
                }
              }
              this.product += s
              break
            default:
              //单项选中
              this.product += this.valueq + "==1"
          }

          break
        default:
          this.product += this.valueq + this.expres[this.expression]


      }

      //重置一下
      this.resets(1)

    },
    //生成 value
    make2() {


      //1，判断连接符

      if (this.expression2 == "7") {
        this.product2 += this.expres2[this.expression2]
        this.resets(2)
        return
      }

      // null !null
      if (this.expression2 == "8") {
        this.product2 += this.valueq2 + " = null"
        this.resets(2)
        return
      }
      if (this.expression2 == "9") {
        this.product2 += this.valueq2 + " != null"
        this.resets(2)
        return
      }

      // !like 
      if (this.expression2 == "11") {
        if (this.product2.includes("!like")) {
          let arr = this.product2.split("!like")
          let as = arr[1]
          let b = JSON.parse(as)
          this.product2 = b.toString()
        } else {
          let a = this.product2
          a = a.replaceAll("，", ",")
          let b = a.split(",")
          let c = JSON.stringify(b)
          this.product2 = this.valueq2 + " !like " + c
        }
        return
      }
      // like 
      if (this.expression2 == "10") {

        if (this.product2.includes("like")) {
          let arr = this.product2.split("like")
          let as = arr[1]
          let b = JSON.parse(as)
          this.product2 = b.toString()
        } else {
          let a = this.product2
          a = a.replaceAll("，", ",")
          let b = a.split(",")
          let c = JSON.stringify(b)
          this.product2 = this.valueq2 + " like " + c
        }
        return
      }


      //2,判断是不是都选上了
      if (this.valueq2 == '') {
        this.$message.error("请输入Question ID/ Variable ID");
        return
      }
      if (this.qtype2 != "ranking" && this.qtype2 != "numericlist" && this.valuea2 == '') {
        this.$message.error("请选择Answer Label");
        return
      }
      if (this.expression2 == '') {
        this.$message.error("请选择表达式");
        return
      }
      //2，判断单多选，为选中/未选中时才能选多项
      if (this.expression2 != "1" && this.expression2 != "2" && this.valuea2.length > 1) {
        this.$message.error("该条件下Answer Label不能多选，请重新选择");
        return
      }


      //3,根据不同题型，分别处理

      switch (this.qtype2) {
        case "single":

          switch (this.expression2) {
            case "2":
              //没有选中
              var s = ''
              for (let item of this.valuea2) {
                if (s == '') {
                  s = this.valueq2 + "!=" + item
                } else {
                  s += "," + this.valueq2 + "!=" + item
                }
              }
              this.product2 += s
              break
            case "1":
              //选中
              s = ''
              for (let item of this.valuea2) {
                if (s == '') {
                  s = this.valueq2 + "=" + item
                } else {
                  s += "," + this.valueq2 + "=" + item
                }
              }
              this.product2 += s
              break
            default:
              //其他表达式
              this.product2 += this.valueq2 + this.expres2[this.expression2] + this.valuea2
          }

          break
        case "grid":
          switch (this.expression2) {
            case "2":
              //没有选中
              s = ''
              for (let item of this.valuea2) {
                if (s == '') {
                  s = this.valueq2 + "!=" + item
                } else {
                  s += "," + this.valueq2 + "!=" + item
                }
              }
              this.product2 += s
              break
            case "1":
              //选中
              s = ''
              for (let item of this.valuea2) {
                if (s == '') {
                  s = this.valueq2 + "=" + item
                } else {
                  s += "," + this.valueq2 + "=" + item
                }
              }
              this.product2 += s
              break
            default:
              //其他表达式
              this.product2 += this.valueq2 + this.expres2[this.expression2] + this.valuea2
          }

          break
        case "multi":
          switch (this.expression2) {
            case "1":
              //选中
              s = ''
              for (let item of this.valuea2) {
                if (s == '') {
                  s = item
                } else {
                  s += "," + item
                }
              }
              this.product2 += s

              break
            case "2":
              //未选中
              s = ''
              for (let item of this.valuea2) {
                if (s == '') {
                  s = "!"+item
                } else {
                  s += ",!" + item
                }
              }
              this.product2 += s
              break
            default:

            //其他表达式不支持
          }

          break
        default:
          this.product2 += this.valueq2 + this.expres2[this.expression2]



      }

      //重置一下
      this.resets(2)

    },
    //保存删除
    savedel(t) {

      var s = []
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.aid)
      });

      var api = this.COMMON.httpUrl + "savedel"
      var data = {
        type: '前因选择',
        t: t,
        aids: s,
        pid: this.pid
      }
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          if (s.length > 0) {
            this.$message.success(rs.message.info);
            this.tableData = rs.message.product
          }

        }

      })

    },
    del() {

      var s = []
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.aid)
      });
      if (s.length == 0) {
        return
      }

      this.$confirm('此操作将永久删除该规则, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.savedel(1)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

  }

}
</script>
<style scoped>
#content {
  background-color: white;
  width: 95%;
  text-align: left;
  padding-left: 50px;
  margin-bottom: 200px;
  border: 0px solid red;
}

.tips {

  font-size: 13px;
  color: #b0b3ae;
}

.tips span {
  font-size: 14px;
  color: green;
  margin-left: 10px;
}

.inp {
  width: 240px;
  margin-right: 10px;

}

.part0 {
  margin-top: 60px;
}

.part2 {
  margin-top: 40px;
  border: 0px solid red;
}

.part3 {
  margin-top: 40px;
}

.part4 {
  margin-top: 15px;
}

.product {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 600px;


}
</style>
<style>
.el-radio-button--mini .el-radio-button__inner {
  padding: 7px 10px;
}
</style>
<template>
    <div>
     
           <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="添加" name="one">
                    <PageChiAdd v-bind:biao="biao"></PageChiAdd>                
                </el-tab-pane>
                <el-tab-pane label="批量" name="two">
                    <PageChiAddBat v-bind:biao="biao"></PageChiAddBat>
                </el-tab-pane>
                  <el-tab-pane label="单项互斥" name="three">
                    <PageChiLiang v-bind:biao="biao"></PageChiLiang>
                </el-tab-pane>
                  <el-tab-pane label="两两互斥" name="four">
                    <PageChiDan v-bind:biao="biao"></PageChiDan>
                </el-tab-pane>

            </el-tabs>
    </div>
</template>
<script>
import PageChiAdd from './PageChiAdd'
import PageChiLiang from './PageChiLiang'
import PageChiDan from './PageChiDan'
import PageChiAddBat from './PageChiAddBat.vue'
export default {
    name: 'PageChi',
    props: {
        type: String
    },
    data() {
        return {
            activeName: 'one',
            biao:{}
        };
    },
    components:{
        PageChiAdd,PageChiAddBat,PageChiLiang,PageChiDan
    },
    methods: {
       
         handleClick() {
             //为了切换后,list重新请求数据,监听对象变化
             localStorage.setItem("chiactive", this.activeName)
           this.biao={current:this.activeName}
        }
    },
      created() {

         if (localStorage.getItem("chiactive")) {
            this.activeName = localStorage.getItem("chiactive")
        }

     
    },

}
</script>
<style>

</style>
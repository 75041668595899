<template>

    <div>
        <div id="content">
            <div class="part1">



                <el-select v-model="pid" placeholder="请选择项目" class="inp" @change="changexm">
                    <el-option v-for="item in options" :key="item.id" :label="item.pname" :value="item.id"></el-option>
                </el-select>
                <br><br>
                <el-input style="width:500px" type="textarea" :rows="10" v-model="textarea" placeholder="请按照如下格式输入,且一行一对:
  Question ID|Answer Code
  Question ID|Answer Code">
                </el-input><br><br>

                <el-button type="primary" @click="onSubmit" :loading="btload">批量添加</el-button>
            </div>
            <div class="part2">

                <div class="op">
                    <el-button type="success" icon="el-icon-circle-plus-outline" @click="savedel(2)">提交保存</el-button>
                    <el-button type="danger" icon="el-icon-delete" @click="del">删除</el-button>
                </div>
<el-divider content-position="left"></el-divider>
                <el-table :data="tableData" ref="multipleTable" style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="aid" label="Aid" width="80" v-if="false">
                    </el-table-column>
                    <el-table-column prop="type" label="Type" width="80">
                    </el-table-column>
                    <el-table-column prop="id" label="Id" width="80">
                    </el-table-column>
                    <el-table-column sortable prop="key" label="Key" width="150">
                    </el-table-column>
                    <el-table-column prop="values" label="Values" width="600">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageChiAddBat',
    props: {
        biao: Object
    },
    watch: {
        biao: {
            deep: true,
            handler(newVal) {
                if (newVal.current == "two") {
                    this.show()
                }

            }
        }
    },
    methods: {
        show() {
            if (localStorage.getItem("selectPid") > 0) {
                this.pid = parseInt(localStorage.getItem("selectPid"))
            }

            var api = this.COMMON.httpUrl + "projectlist2"
            var data = {
                type: '同题互斥',
                pid: this.pid
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.options = rs.message.options
                    this.tableData = rs.message.product


                }

            })
        },
        del() {

            var s = []
            this.$refs.multipleTable.selection.forEach((Ele) => {
                s.push(Ele.aid)
            });
            if (s.length == 0) {
                return
            }

            this.$confirm('此操作将永久删除该规则, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.savedel(1)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        savedel(t) {

            var s = []
            this.$refs.multipleTable.selection.forEach((Ele) => {
                s.push(Ele.aid)
            });

            var api = this.COMMON.httpUrl + "savedel"
            var data = {
                t: t,
                aids: s,
                pid: this.pid
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    if (s.length > 0) {
                        this.$message.success(rs.message.info);
                        this.tableData = rs.message.product
                    }

                }

            })

        },

        changexm(v) {
            var vi = parseInt(v)
            localStorage.setItem("selectPid", vi)
            this.show()
        },
        onSubmit() {
            this.btload = true
            var api = this.COMMON.httpUrl + "huchiaddbat"
            var data = {
                pid: this.pid,
                textarea: this.textarea
            }
            this.axios.post(api, data).then((response) => {
                this.textarea = ''
                this.btload = false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);

                } else {
                    this.tableData = rs.message

                }

            })

        }

    },
    data() {
        return {
            btload: false,
            textarea: '',
            pid: '',
            options: [],

            multipleSelection: [],
            tableData: []
        }
    },
    created() {
        if (localStorage.getItem("selectPid") > 0) {
            this.pid = parseInt(localStorage.getItem("selectPid"))
        }

        var api = this.COMMON.httpUrl + "projectlist2"

        var data = {
            type: '同题互斥',
            pid: this.pid
        }
        this.axios.post(api, data).then((response) => {
            var rs = response.data
            if (rs.code) {
                this.$message.error(rs.message);
            } else {
                this.options = rs.message.options
                this.tableData = rs.message.product


            }

        })

    },

}
</script>
<style scoped>
  #content {
background-color: white;
    padding-top: 100px;
    padding-left: 100px;
}

.inp {
    width: 180px;
    margin-right: 10px;
}

.op {
    padding-bottom: 20px;
}

.part2 {
    margin-top: 100px;
}

.el-descriptions {
    color: #409EFF;
}
</style>
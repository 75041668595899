<template>

  <div>
    <div id="content">

      <el-row :gutter="70">
        <el-col :span="13">

          <div>
            <el-select v-model="pid" placeholder="请选择项目" class="inp" @change="changexm">
              <el-option v-for="item in optionp" :key="item.id" :label="item.pname" :value="item.id"></el-option>
            </el-select>

            <el-button type="primary" style="float:right;" @click="onSubmit" :loading="btload">添加</el-button>

          </div>
          <div class="part0">
            <el-divider content-position="left">KEY</el-divider>
          </div>

           <div class="part1" style="padding-top:30px">    
          <el-input v-model="valueqh" placeholder="输入自定义命名（英文/英文+数字，且以字母开头）" @blur="keycheck"></el-input>          
          </div>

          <div class="part0">
            <el-divider content-position="left">VALUES</el-divider>
          </div>
          <div class="part1">
            <div style="height: 30px;">
              <p class="tips" v-if="qtype != ''">Question Type:<span>{{ qtype }}</span></p>
            </div>


            <el-select class="inp" v-model="valueq" clearable filterable @change="changeselect()"
              placeholder="请输入Question ID/ Variable ID">
              <el-option v-for="item in optionsq" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>


            <el-select class="inp" v-model="valuea" clearable :multiple="multiple" filterable allow-create collapse-tags
              default-first-option placeholder="请选择Answer Label">
              <el-option v-for="item in optiona" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>




          </div>
          <div class="part2">

            <el-radio-group v-model="expression" size="mini">
              <el-radio-button label="1" :disabled="able[1]">单项选中</el-radio-button>
              <el-radio-button label="2" :disabled="able[2]">任一选中</el-radio-button>
              <el-radio-button label="3" :disabled="able[3]">均未选中</el-radio-button>
              <el-radio-button label="4" :disabled="able[4]">大于</el-radio-button>
              <el-radio-button label="5" :disabled="able[5]">大于等于</el-radio-button>
              <el-radio-button label="6" :disabled="able[6]">小于</el-radio-button>
              <el-radio-button label="7" :disabled="able[7]">小于等于</el-radio-button>
              <el-radio-button label="8">null</el-radio-button>
              <el-radio-button label="9">!null</el-radio-button>
              <el-radio-button label="10">&</el-radio-button>
              <el-radio-button label="11">or</el-radio-button>
            </el-radio-group>


          </div>


          <div class="part3">

            <el-input spellcheck="false" placeholder="内容生成,可编辑" type="textarea" v-model="product" class="product"
              rows="3"></el-input>
          </div>
          <div class="part4">
            <el-button type="info" size="small" @click="reset(1)">重置</el-button>
            <el-button type="warning" size="small" @click="make">解析表达式</el-button>

          </div>




        </el-col>
        <el-col :span="11">

          <div class="op">
            <el-button type="success" icon="el-icon-circle-plus-outline" @click="savedel(2)">提交保存</el-button>
            <el-button type="danger" icon="el-icon-delete" @click="del">删除</el-button>
          </div>
          <el-divider content-position="left"></el-divider>
          <el-table :data="tableData" ref="multipleTable" style="width:100%">
            <el-table-column type="selection">
            </el-table-column>
            <el-table-column prop="aid" label="Aid" width="80" v-if="false">
            </el-table-column>
            <el-table-column prop="type" label="Type" width="80">
            </el-table-column>
            <el-table-column prop="id" label="Id" width="40">
            </el-table-column>
            <el-table-column sortable prop="key" label="Key">
            </el-table-column>
            <el-table-column prop="values" label="Values">
            </el-table-column>
          </el-table>

        </el-col>
      </el-row>

    </div>
  </div>
</template>
<script>
export default {
  name: 'PageHong',
  props: {
    type: String
  },
  data() {

    return {

      // key部分
      valueqh:'',

       // values部分
      btload: false,
      pid: [], //项目id-- 通用
      optionp: [], //项目选项-- 通用
      tableData: [],
      able: [false, false, false, false, false, false, false, false, false, false],
      expression: '',//表达式
      expres: ["0", "==", "", "", ">", ">=", "<", "<=","=null","!=null", " and ", " or "],
      qtype: '', //当前answer Type
      multiple: true,
      product: '', //key生成的表达式
      optionsq: [],//question选项-- 通用
      valueq: [],//question 值
      optiona: [], //anser选项
      valuea: [],  //answer值



    }
  },

  created() {
     var api = this.COMMON.httpUrl + "projectlist"
        var data = {
            pid: this.pid
        }
        this.axios.post(api, data).then((response) => {
            var rs = response.data
            if (rs.code) {
                this.$message.error(rs.message);
            } else {
                this.optionp = rs.message.data
                 if (localStorage.getItem("selectPid") > 0) {
                    this.pid = parseInt(localStorage.getItem("selectPid"))
                    this.show()
                }


            }

        })
  },
  methods: {
    keycheck(){
      if(this.valueqh==''){return}
      var api = this.COMMON.httpUrl + "checkkey"
      var data = {
        key:this.valueqh,
        pid: this.pid
      }
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);
        }

      })

    },
    //改变左侧下拉框,更新右侧下拉选项
    changeselect() {

      var vid = this.valueq
      var api = this.COMMON.httpUrl + "yinoption"
      var data = {
        vid: vid,
        pid: this.pid
      }
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
         this.optiona = rs.message.data
            this.qtype = rs.message.type
          this.chable(rs.message.type)

        }

      })

    },
    //更改项目
    changexm(v) {
      var vi = parseInt(v)
      localStorage.setItem("selectPid", vi)
      this.show()
    },
    //初始化加载数据
    show() {

      if (localStorage.getItem("selectPid") > 0) {
        this.pid = parseInt(localStorage.getItem("selectPid"))
      }

      var api = this.COMMON.httpUrl + "yinSelect"
      var data = {
        pid: this.pid,
        tp:"宏"
      }
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.optionsq = rs.message.optionsq;
          this.optionp = rs.message.optionp
          this.tableData = rs.message.tabledata
        }

      })
    },
    //改变表达式可选
    chable(t) {
       switch (t) {
          case "ranking":
            this.able = [false, false, true, false, false, false, false, false]
            break
          case "numericlist":
            this.able = [false, false, true, false, false, false, false, false]
            break
          case "multi":
            this.able = [false, false, false, false, true, true, true, true]
            break
          default:
            this.able = [false, false, false, false, false, false, false, false]
        }

    },

    //添加暂存
    onSubmit() {
      this.btload = true
      var api = this.COMMON.httpUrl + "hongadd"
      var data = {
        pid: this.pid,
        values: this.product,
        key: this.valueqh
      }
      this.axios.post(api, data).then((response) => {
        this.btload = false
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);

        } else {
          this.tableData = rs.message
          this.product = ''
          this.valueqh = ''

        }

      })

    },

    //重置
    reset(ps) {
     this.valueq = ''
        this.valuea = ''
        this.expression = ''
        this.qtype = ''
        if(ps==1) {
          this.product=''
        }
        this.able = [false, false, false, false, false, false, false, false]

    },
    //生成
    make() {


      //1，判断连接符

      if (this.expression == "10" || this.expression == "11") {
        this.product += this.expres[this.expression]
        this.reset()
        return
      }

      // null !null
      if ( this.expression == "8") {        
        this.product += this.valueq + " = null"
        this.resets()
        return
      }
      if ( this.expression == "9" ) {        
        this.product += this.valueq + " != null"
        this.resets()
        return
      }

      //,2,判断是不是都选上了
      if (this.valueq == '') {
        this.$message.error("请输入Question ID/ Variable ID");
        return
      }
      if (this.qtype != "ranking" && this.qtype != "numericlist" && this.valuea == '') {
        this.$message.error("请选择Answer Label");
        return
      }
      if (this.expression == '') {
        this.$message.error("请选择表达式");
        return
      }
      //2，判断单多选，为任一选中/均未选中时才能选多项
      if (this.expression != "2" && this.expression != "3" && this.valuea.length > 1) {
        this.$message.error("该条件下Answer Label不能多选，请重新选择");
        return
      }


      //3,根据不同题型，分别处理

      switch (this.qtype) {
        case "single":

          switch (this.expression) {
            case "2":
              //任一选中
              this.product += this.valueq + " in [" + this.valuea + "]"
              break
            case "3":
              //均未选中
              var s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = this.valueq + "!=" + item
                } else {
                  s += " and " + this.valueq + "!=" + item
                }
              }
              this.product += s
              break
            default:
              //其他表达式
              this.product += this.valueq + this.expres[this.expression] + this.valuea
          }

          break
        case "grid":
          switch (this.expression) {
            case "2":
              //任一选中
              this.product += this.valueq + " in [" + this.valuea + "]"
              break
            case "3":
              //均未选中
              s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = this.valueq + "!=" + item
                } else {
                  s += " and " + this.valueq + "!=" + item
                }
              }
              this.product += s
              break
            default:
              //其他表达式
              this.product += this.valueq + this.expres[this.expression] + this.valuea
          }

          break
        case "multi":
          switch (this.expression) {
            case "2":
              //任一选中

              s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = item + "==1"
                } else {
                  s += " or " + item + "==1"
                }
              }
              this.product += s

              break
            case "3":
              //均未选中
              s = ''
              for (let item of this.valuea) {
                if (s == '') {
                  s = item + "==0"
                } else {
                  s += " and " + item + "==0"
                }
              }
              this.product += s
              break
            default:
              //单项选中
              this.product += this.valuea + "==1"
          }

          break
        default:
          this.product += this.valueq + this.expres[this.expression]


      }

      //重置一下
      this.reset()

    },
   
    //保存删除
    savedel(t) {

      var s = []
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.aid)
      });

      var api = this.COMMON.httpUrl + "savedel"
      var data = {
        type:'宏',
        t: t,
        aids: s,
        pid: this.pid
      }
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          if (s.length > 0) {
            this.$message.success(rs.message.info);
            this.tableData = rs.message.product
          }

        }

      })

    },
      del() {

            var s = []
            this.$refs.multipleTable.selection.forEach((Ele) => {
                s.push(Ele.aid)
            });
            if (s.length == 0) {
                return
            }

            this.$confirm('此操作将永久删除该规则, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.savedel(1)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

  }

}
</script>
<style scoped>
  #content {
background-color: white;
  width: 95%;
  text-align: left;
  padding-left: 50px;
  margin-bottom: 300px;
}

.tips {

  font-size: 13px;
  color: #b0b3ae;
}

.tips span {
  font-size: 14px;
  color: green;
  margin-left: 10px;
}

.inp {
  width: 240px;
  margin-right: 10px;
}

.part0 {
  margin-top: 60px;
}

.part2 {
  margin-top: 40px;
}

.part3 {
  margin-top: 40px;
}

.part4 {
  margin-top: 15px;
}

.product {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 600px;


}
</style>
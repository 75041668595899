<template>

    <div id="content">


        <div class="login">

            <p class="tit">Logic Admin</p>
            <el-input v-model="name" placeholder="用户名" class="inp"></el-input>
            <el-input v-model="password" placeholder="密码" class="inp" show-password></el-input>


            <el-button type="primary" class="bt" @click="login">登录系统</el-button>



        </div>




    </div>

</template>
<script>
export default {
    name: 'PageLogin',
    props: {
        type: String
    },
    data() {
        return {
            name: '',
            password: ''
        }
    },
    methods: {

        login() {

            var api = this.COMMON.httpUrl + "checklogin"
            var data = { name: this.name, password: this.password }
            this.axios.post(api, data).then((response) => {
                var rs = response.data

                if (rs.code) {
                    this.$message.error(rs.message);
                } else {

                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                       this.$message.success("登录成功!");
                       var token=rs.message["token"]
                       var uname=rs.message["uname"]
                       var level=rs.message["level"]
                         localStorage.setItem("token", token)
                         localStorage.setItem("uname", uname)
                         localStorage.setItem("level", level)
                    setTimeout(() => {
                        loading.close();
                        this.$router.push('/index');
                    }, 1000)


                }

            })
        },
    }

}
</script>
<style scoped>
#content {
    height: 100%;
    background-image: url(../../assets/bg.jpeg);
}

.login {
    width: 500px;
    height: 350px;
    background: red;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -250px 0 0 -250px;

    border-radius: 14px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 3px 3px 2px #a8a8a8;

    text-align: center;
}

.tit {
    color: white;
    font-size: 30px;
    font-weight: 800;
    padding-top: 40px;
}

.inp {
    width: 300px;
    margin-top: 30px;
}

.bt {
    width: 300px;
    margin-top: 30px;
}
</style>
import VueRouter from "vue-router"

//引入组件

import PageIndex from '../components/PageIndex'
import PageLogin from '../components/PageLogin'
import PageChi from '../components/PageChi'
import PageYin from '../components/PageYin'
import PageHong from '../components/PageHong'
import PageExcel from '../components/PageExcel'
import PageSet from '../components/PageSet'

//创建并暴露一个路由器

const router = new VueRouter({

    routes: [{
            path: '/',
            redirect: '/index'

        },
        {
            path: '/index',
            name: 'index',
            component: PageIndex,
            children: [

                {
                    path: '/chi',
                    name: 'chi',
                    component: PageChi
                },
                {
                    path: '/yin',
                    name: 'yin',
                    component: PageYin
                },
                {
                    path: '/hong',
                    name: 'hong',
                    component: PageHong
                },
                {
                    path: '/excel',
                    name: 'excel',
                    component: PageExcel
                },
                {
                    path: '/set',
                    name: 'set',
                    component: PageSet
                }

            ]
        },
        {
            path: '/login',
            name: 'login',
            component: PageLogin
        },


    ]


})

// router.beforeEach((to, from, next) => {
//     if (to.path === '/login') {

//         let token = localStorage.getItem('token');
//         if (token === null || token === '') {
//             next();
//         } else {
//             router.push("/index")
//         }
//     } else {
//         let token = localStorage.getItem('token');

//         if (token === null || token === '') {
//             next('/login');
//         } else {
//             next();
//         }
//     }
// });


export default router
<template>

    <div>
        <div id="content">
               <div class="op">
                  
                    <el-button type="danger" icon="el-icon-delete" :loading="btload" @click="del">删除</el-button>
                </div>
            <div class="plist">
                <el-table :data="tableData" ref="multipleTable" style="width: 100%">
                   <el-table-column type="selection" width="55">
                    </el-table-column>
                     <el-table-column prop="id" label="id" width="80" v-if="false">
                    </el-table-column>
                    <el-table-column  sortable prop="created_time" label="创建日期" width="220">
                    </el-table-column>
                    <el-table-column sortable prop="pname" label="项目名称" width="300">
                    </el-table-column>
                    <el-table-column prop="userid" label="导出数据" width="120">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" icon="el-icon-download" @click="downs(scope.row.id)">
                            </el-button>

                        </template>
                    </el-table-column>
                      <el-table-column prop="last_time" label="最近导出" width="200">
                    </el-table-column>
                </el-table>
            </div>
            <div class="fenye">
                <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="15"
                    layout="total, prev, pager, next" :total=total>
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageExcelList',
    props: {
        biao: Object
    },
    data() {
        return {
            btload:false,
            multipleSelection: [],
            tableData: [],
            currentPage: 1,
            total: 1,
            token:''
        }
    },

    watch: {
        biao: {
            deep: true,
            handler(newVal) {
                if (newVal.current == "second") {
                    this.handleCurrentChange(1)
                }

            }
        }
    },
    created() {
        this.handleCurrentChange(1)
         const token = localStorage.getItem('token')
        if (token) {    
            this.token=token;
        }

    },

    methods: {
        downs(s) {

             var api = this.COMMON.httpUrl+"checksave"
            var data = {
                pid: s
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    if(this.token==""){
                        this.$message.error("您的登录状态异常,请重新登录后操作");
                    }else{
                         window.location.href=this.COMMON.httpUrl+"downs?pid="+s+"&token="+this.token
                    }
                    
                }

            })

            
        },
        handleCurrentChange(page) {
            var api = this.COMMON.httpUrl+"projectlist"
            var data = {
                page: page
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                   
                    this.tableData = rs.message.data
                    this.currentPage = rs.message.current_page
                    this.total = rs.message.total
                }

            })
        },
         del() {

            var s = []
            this.$refs.multipleTable.selection.forEach((Ele) => {
                s.push(Ele.id)
            });
            if (s.length == 0) {
                return
            }

            this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.savedel()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
         savedel() {
            this.btload=true
            var s = []
            this.$refs.multipleTable.selection.forEach((Ele) => {
                s.push(Ele.id)
            });
            
            var api = this.COMMON.httpUrl + "projectdel"
            var data = {     
                pids: s
            }
            this.axios.post(api, data).then((response) => {
                this.btload=false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.$message.success(rs.message.d);
                    this.tableData = rs.message.p.data
                    this.currentPage = rs.message.p.current_page
                    this.total = rs.message.p.total
                
                }

            })

        },
        

      

    }

}
</script>
<style scoped>
  #content {
background-color: white;
    width: 1000px;
    text-align: left;
    padding-left: 100px;
    padding-top:20px;
}
.op{
    padding: 20px 0;
}

.fenye {
    margin-top: 20px;
}
</style>
<template>

    <div>
        <div id="content">

            <el-form ref="form" label-width="80px">
                <el-form-item label="项目名称">
                    <el-input v-model="pname" placeholder="请输入项目名称" v-loading="loading"></el-input>
                </el-form-item>

                <el-form-item label="项目数据">
                    <el-upload v-loading="loading" class="upload-demo" drag :action="upurl"
                        name="excel" accept=".xls,.xlsx" :limit="1" :before-upload="beforeAvatarUpload" multiple
                        :on-success="onSuccess" ref="upload">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传.xls .xlsx文件</div>
                    </el-upload>
                </el-form-item>
                <el-form-item>

                    <el-button type="primary" @click="onSubmit" :loading="loading">立即提交</el-button>
                    <el-button @click="clear">重置</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>
<script>
export default {

    name: 'PageExcel',
    props: {
        type: String
    },
    data() {
        return {
            loading: false,
            pname: '',
            pexcel: '',
            upurl:''

        }
    },
    created(){
    this.upurl =this.COMMON.httpUrl+"upload?token="+localStorage.getItem('token')

    },
    methods: {
        beforeAvatarUpload(file) {
            let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)

            //    alert(fileName)
            const extension = fileName === 'xls'
            const extension2 = fileName === 'xlsx'
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
                return false
            }

        },
        onSuccess(res) {
            if (res.code) {
                this.$message({
                    message: res.message,
                    type: 'error'
                });
            } else {
                this.pexcel = res.message
            }

        },
        clear() {
            this.$refs['upload'].clearFiles()
            this.pname = ""
        },
        onSubmit() {
            this.loading = true
            var api = this.COMMON.httpUrl+"addproject"
            var data = { pname: this.pname, pexcel: this.pexcel}
            this.axios.post(api, data).then((response) => {
                var rs=response.data     
                this.loading = false
                if(rs.code){
                this.$message.error(rs.message);    
                }else{
                this.clear() 
                this.$message.success('恭喜您,添加成功');
                }
                
            })
        }

    }

}
</script>
<style scoped>
  #content {
background-color: white;
    width: 900px;
    text-align: left;
    padding-top: 100px;
    padding-left: 100px;
 
}

.upload-demo {
    width: 300px;
}

.el-input {
    width: 360px;
}
</style>